<template>
  <div class="lxp-unauthorized">
    <LandingPageLayout>
      <div class="la__wrapper">
        <img src="@/assets/images/unauthorized/incognito.svg" alt="incognito" />
        <h3>{{ $t("auth.error.unauthorized.title") }}</h3>
        <p>{{ $t("auth.error.unauthorized.subheading") }}</p>
        <Button
          variant="primary"
          size="default"
          className="text-uppercase"
          @click="login(getRedirectURI)"
          >{{ $t("landing_page.header.button.login") }}</Button
        >
      </div>
    </LandingPageLayout>
  </div>
</template>

<script>
import userLanguageMixin from "@/core/mixins/userLanguage";
import authenticationMixin from "@/core/mixins/authentication";

import LandingPageLayout from "../components/Layout/LandingPageLayout.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    LandingPageLayout
  },
  mixins: [userLanguageMixin, authenticationMixin],
  computed: {
    ...mapGetters(["allConfig"])
  },
  mounted() {
    this.$emit("update:layout", "main");
  }
};
</script>
<style lang="scss" scoped>
.lxp-unauthorized {
  .la__wrapper {
    @include center;
    height: 495px;
    padding: 24px;
    h3 {
      @include h3;
      margin: 26px 0 20px;
    }
    p {
      @include subtitle-regular;
      margin-bottom: 30px;
    }
  }
}
</style>
